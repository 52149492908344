import React, {Component} from "react";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      burgerMenuPressed: false,
      moreResourcesSubMenuPressed: false
    };
  }
  render () {
    let isHovering = false;
    return (
      <header className="header">
        <div className="header-container">
          <div className="icon-container"/>
          <div className="menu-container">
            <div onClick={()=>window.location.href = "https://sinkmount.com/#what-is"} className="menu-item">What it is</div>
            <div onClick={()=>window.location.href = "https://sinkmount.com/#story"} className="menu-item">The story behind it</div>
            <div onClick={()=>window.location.href = "https://sinkmount.com/#faq"} className="menu-item">Faq</div>
            <div onClick={()=>window.location.href = "https://sinkmount.com/#contact-us"} className="menu-item">Contact us</div>
            <div style={{position: "relative"}} onMouseEnter={()=> {this.setState({isHovering: true})}} className="menu-item">
              More resources
              <div onMouseLeave={()=> {this.setState({isHovering: false})}} style={this.state.isHovering ?  {display: "flex"} : {display: "none"}} className="sub-menu-container">
                <a href="https://sinkmount.com/how-to-install-a-kohler-whitehaven-with-petes-sink-mount-k-6488/">How to install a Kohler Whitehaven with Pete's Sink Mount</a>
                <a href="https://sinkmount.com/how-to-install-a-fireclay-sink-with-petes-sink-mount/">How to Install a Fireclay Sink with Pete's Sink Mount</a>
                <a href="https://sinkmount.com/wp-content/uploads/2017/08/sink_mount_flyer-digital.pdf">Promotional Flyer</a>
                <a href="https://sinkmount.com/wp-content/uploads/2017/04/install-instructions.NB1_.pdf">Mounting Instructions</a>
                <a href="https://sinkmount.com/what-people-are-saying/">What People Are Saying</a>
              </div>
            </div>            
            <div onClick={()=>window.location.href = "https://sinkmount.com/product/petes-sink-mount/"} className="menu-item">Buy now!</div>
          </div>
          <div className="burger-container">
            <i onClick={()=>{this.setState({burgerMenuPressed: !this.state.burgerMenuPressed})}} className={this.state.burgerMenuPressed ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
        {
          this.state.burgerMenuPressed &&
          <div className="burger-menu">
            <div style={{borderTopWidth: 1}} className="menu-item">
              <span onClick={()=>window.location.href = "https://sinkmount.com/#what-is"}>What it is</span>
            </div>
            <div className="menu-item">
              <span onClick={()=>window.location.href = "https://sinkmount.com/#story"}>The story behind it</span>
            </div>
            <div className="menu-item">
              <span onClick={()=>window.location.href = "https://sinkmount.com/#faq"}>Faq</span>
            </div>
            <div className="menu-item">
              <span onClick={()=>window.location.href = "https://sinkmount.com/#contact-us"}>Contact us</span>
            </div>
            <div className="menu-item more-resources">
              <span>More resources</span><i style={{padding: "0.5em"}} onClick={()=>{this.setState({moreResourcesSubMenuPressed: !this.state.moreResourcesSubMenuPressed})}} className="fas fa-chevron-down"/>
            </div>
            {
              this.state.moreResourcesSubMenuPressed &&
              <div className="sub-menu-container">
                <a href="https://sinkmount.com/how-to-install-a-kohler-whitehaven-with-petes-sink-mount-k-6488/">How to install a Kohler Whitehaven with Pete's Sink Mount</a>
                <a href="https://sinkmount.com/how-to-install-a-fireclay-sink-with-petes-sink-mount/">How to Install a Fireclay Sink with Pete's Sink Mount</a>
                <a href="https://sinkmount.com/wp-content/uploads/2017/08/sink_mount_flyer-digital.pdf">Promotional Flyer</a>
                <a href="https://sinkmount.com/wp-content/uploads/2017/04/install-instructions.NB1_.pdf">Mounting Instructions</a>
                <a href="https://sinkmount.com/what-people-are-saying/">What People Are Saying</a>
              </div>
            }
            <div style={{borderTopWidth: this.state.moreResourcesSubMenuPressed ? 1 : 0}} className="menu-item">
              <span onClick={()=>window.location.href = "https://sinkmount.com/product/petes-sink-mount/"}>Buy now!</span>
            </div>
            {/*<div className="menu-item search">
              <span>
                <input placeholder="Search.."/><i className="fas fa-search"/>
              </span>
            </div>*/}
          </div>
        }        
      </header>
    );
  }
}

export default Header;