import logo from './logo.svg';
import "./Assets/styles/style.scss";
import Buy from "./Pages/Buy";
import React from "react";

function App() {
  return <Buy/>;  
}

export default App;
