import React, {Component} from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import SinkImg from "../Assets/images/white-background-sink-mount-1.jpg";

class Buy extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{display: "flex", flexDirection: "column"}}>
        <Header/>
        <div className="image-container">
          <img src={SinkImg}/>
        </div>
        <div className="product-title-buy-container">
          <div className="title-price">
            <div className="title">Pete's Sink Mount Kit</div>
            <div className="price">- $295</div>
          </div>
          <div className="learn-more-add-to-cart">
            <a className="learn-more" href="https://sinkmount.com">Learn More</a>
            <div onClick={()=>window.location.href = "https://sinkmount.com/product/petes-sink-mount"} className="add-to-cart">
              <i className="fas fa-cart-plus"/>
              Add To Cart
            </div>
          </div>
          <div className="customer-reviews">
            <span>(18 customer reviews)</span>
          </div>
        </div>
        <div className="product-description-container">
          <div className="title">The Ultimate Sink Mounting Solution</div>
          <div className="description">
            <p>
              Easy, safe and accurate way to install undermount sinks. Brackets mount to cabinet side walls and have 1 3/8" vertical adjustment. No cabinet modifications or special tools required. Excellent for heavy sinks like cast iron, granite, composite stone, concrete and fireclay. Built in "play" allows for uneven sink bottoms. Made in the USA.
            </p>
          </div>
          <div className="checkboxes">
            <div><i className="fas fa-check-square"/> Fast - Save installation time and money</div>
            <div><i className="fas fa-check-square"/> Easy - Professional results due to unlimited, precision adjustment even after countertop is installed</div>
            <div><i className="fas fa-check-square"/> 100% stainless steel construction ensures no mold and no rot from future water seepage</div>
            <div><i className="fas fa-check-square"/> Tested to over 400 lbs</div>
            <div><i className="fas fa-check-square"/> Support sinks up to 46" wide</div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default Buy;