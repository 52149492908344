import React from "react";

const Footer = props => {
  return (
    <footer className="footer">
      <div className="footer-container sitemap">
        <div className="header">Sitemap</div>
        <a href="https://sinkmount.com">> Home</a>
        <a href="https://sinkmount.com/what-people-are-saying/">> What People Are Saying</a>
        <a href="https://sinkmount.com/how-to-install-a-fireclay-sink-with-petes-sink-mount/">> How to Install a Fireclay Sink with Pete's Sink Mount</a>
        <a href="https://sinkmount.com/how-to-install-a-kohler-whitehaven-with-petes-sink-mount-k-6488/">> How to install a Kohler Whitehaven with Pete's Sink Mount</a>
        <a href="https://sinkmount.com/shop/">> Shop</a>
      </div>
      <div className="footer-container">
        <div className="header">Legal</div>
        <a href="https://sinkmount.com/terms-of-service/">> Policies & Terms of Services</a>
        <a href="https://sinkmount.com/cancellation-return-and-exchange-policy/">> Cancellation, Return and Exchange Policy</a>
        <a href="https://sinkmount.com/privacy-policy-2/">> Privacy Policy</a>
        <a href="https://sinkmount.com/shipping-policy/">> Shipping Policy</a>
      </div>
      <div className="footer-container">
        <div className="header">Pete's Sink Mount</div>
        <a href="#"><i className="fas fa-home"/> Vancouver, WA</a>
        <a href="tel:360-521-4047"><i className="fas fa-phone"/> 360-521-4047</a>
        <a href="mailto:contact@sinkmount.com"><i className="fas fa-envelope"/> contact@sinkmount.com</a>
        <a href="https://sinkmount.com"><i className="fas fa-globe"/> https://sinkmount.com</a>
      </div>
      <div className="img-container">
        <div className="img-row row-1">
          <div className="img1"/><div className="img2"/>
        </div>
        <div className="img-row row-2">
          <div/>
        </div>
        <div className="img-row row-3">
          <div/>
        </div>
      </div>
      <div className="sub-footer">
        <div className="sub-footer-container">
          <span>Copyright  ©2020 Pete's Home Innovations. All rights reserved.</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;